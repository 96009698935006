import React from "react";

function Loader() {
  return (
    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
      <div
        className="spinner-border text-primary "
        role="status"
        title="loading..."
      >
        <span className="visually-hidden ">Loading...</span>
      </div>
    </div>
  );
}

export default Loader;
